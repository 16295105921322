<template>
  <div>
    <b-dropdown
      ref="dropdown"
      append-to-body
      aria-role="list"
      scrollable
      max-height="200"
      trap-focus
      class="is-horizontal"
    >

      <b-dropdown-item
        v-for="item of data"
        :key="item.id"
        aria-role="listitem"
        @click="select(item)"
        >
        <span> {{ listItems(item) }} </span>
        </b-dropdown-item
      >
    </b-dropdown>

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { ApiUsers } from '@/api-routes.js'
import FormTemplateMixin from '@/mixins/FormTemplateMixin'

export default {
  name: 'app-dropdown-receivers',
  // mixins: [FormTemplateMixin],
  props: {
    data: {
      default: null
    },
  },

  components: {
    //
  },
  data () {
    return {
      name: null
    }
  },

  computed: {
    ...mapGetters([
      // 'items'
    ]),
  },

  methods: {
    ...mapActions([
      // 'liveSearch',
      // 'setItems'
    ]),

    select (value) {
      this.$emit('onSelected', value)
    },

    // List of items might be list of projects, having user
    // or list of users, having project attached to it
    listItems(item) {
      if (this.data.length) {
        if (item.hasOwnProperty('projects')) {
          let projects = []
          let dots = ''
          item.projects.forEach(project => {
            projects.push(project.title)
          })
          if (projects.length > 2) {
            dots = ' ...'
          }
          projects.join(',')
          return item.name + ' [' + projects + dots + ']'
        }
        if (item.hasOwnProperty('user')) {
          return item.title
        }
        return item.name
      }
      console.log('NO DATA')
    }
  },

  mounted () {
    // console.log('dropdown open', this.$refs, this.data.length)
    if (this.$refs.hasOwnProperty('dropdown') && this.data.length) {
      this.$refs.dropdown.isActive = true
    }
  }
}
</script>
<style scoped>
  .dropdown.is-horizontal {
    display: inherit;
  }
</style>
