<template>
  <div>
    <hero-bar>
      <span class="capitalize"> {{ title }} </span>
      <router-link slot="right" to="/messages" class="button">
        {{ $getTranslation("general.views.messaging") }}
      </router-link>
    </hero-bar>

    <section class="section is-main-section">
      <card-component icon="account">
        <p class="has-background-warning has-text-centered" v-if="isLoggedUser.roles === 'participant'">{{ $getTranslation('messaging.participant-info') }}</p>
        <ValidationObserver ref="observer" v-slot="{ passes }">
          <form ref="form" @submit.prevent="handleSubmit" noValidate>
            <input
              type="hidden"
              v-model="isLoggedUser.userId"
            />
            <b-field
              horizontal
              :label="$getTranslation('form.subject.label')"
              :placeholder="$getTranslation('form.subject.placeholder')"
              >
              <b-input expanded v-model="form.subject"></b-input>
            </b-field>

            <!-- provide user on keyup-native, or add user with @ as in all other apps -->
            <!-- <template #trigger> -->

            <InputWithValidation
              horizontal
              rules="required"
              type="receiver"
              v-model="form.receiver"
              icon="account"
              :tooltip="getTooltip"
              :label="$getTranslation('form.receiver.label')"
              :placeholder="$getTranslation('form.receiver.placeholder')"
              @keyup.native="findUser()"
              provider="provider" />
            <!-- somehow is not working without p tag :( -->
            <p horizontal v-model="name" class="is-hidden">{{ name }}</p>
            <input type="hidden" model="form.receiver_id" />
            <input type="hidden" model="form.receiver_ids[]" />

            <app-dropdown-receivers v-if="items !== null" :data="items" @onSelected="onSelected"></app-dropdown-receivers>
            <!-- provide required -->
            <!-- <b-field
              horizontal
              :label="$getTranslation('form.message.label')"
              :placeholder="$getTranslation('form.message.placeholder')"
              >
              <b-input type="textarea" v-model="form.message"></b-input>
            </b-field> -->

            <TextareaWithValidation
              horizontal
              rules="required"
              type="receiver"
              v-model="form.message"
              :label="$getTranslation('form.message.label')"
              :placeholder="$getTranslation('form.message.placeholder')"
              provider="provider" />

            <hr />
            <b-field horizontal v-if="isLoggedUser.roles !== 'participant'">
              <b-switch v-model="isNotification">Notification</b-switch>
              <b-switch v-model="isEmail">Email</b-switch>
            </b-field>

            <b-field horizontal>
              <b-field grouped>
                <div class="control">
                  <b-button
                    native-type="submit"
                    type="is-primary"
                    @click="passes(submit)"
                    >{{ $getTranslation("form.submit") }}</b-button
                  >
                </div>
              </b-field>
            </b-field>
          </form>
        </ValidationObserver>
      </card-component>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { ApiMessaging, ApiUsers, ApiProjects } from '@/api-routes.js'
import FormTemplateMixin from '@/mixins/FormTemplateMixin'
import Dropdown from './Dropdown'

export default {
  name: 'app-create-messaging',
  props: ['slug'],
  mixins: [FormTemplateMixin],
  components: {
    'app-dropdown-receivers': Dropdown
  },
  data () {
    return {
      title: this.$getTranslation('form.general.create') + ' ' + this.$getTranslation('form.message.label'),
      endpoint: ApiMessaging,
      isNotification: true,
      isEmail: false,
      name: null,
      receiver_ids: []
    }
  },

  computed: {
    ...mapGetters([
      'item',
      'items',
      'isLoggedUser',
    ]),

    getTooltip () {
      if (this.isLoggedUser.roles === 'participant') {
        return this.$getTranslation('messaging.receiver-tooltip-participant')
      }

      return this.$getTranslation('messaging.receiver-tooltip')
    }
  },

  methods: {
    ...mapActions([
      'fetchItem',
      'liveSearch',
      'saveItem',
      'setItems'
    ]),

    /**
     * submit will dynamically handle create or update action according to the
     * method defined
     */
    submit () {
      let media = []
      if(this.isNotification) {
        media.push('notification')
      }
      if(this.isEmail) {
        media.push('email')
      }
      let medias = media.join()

      this.form.media = medias
      let payload = {
        method: this.formMethod,
        data: this.fetchData()
      }
      // console.log(this.isNotification, this.isEmail)

      payload.endpoint = this.endpoint
      // console.log(this.slug, this.endpoint)
      this.saveItem(payload)
        .then(result => {
          // console.log(this.error, result.success)
          // if(!this.error) {}
          this.$toast.success(this.$getTranslation('form.message.label') + this.$getTranslation('form.general.sent'))
          // console.log('error - items not found !!!?', this.error)
          // this.$router.push('/messages')
        })
        .catch(error => {
          console.log("catch error form:", error);
          // TODO - just for demo ! is a CORS error
          this.$toast.danger(this.$getTranslation('form.message.label') + this.$getTranslation('form.general.not-sent'))

          this.$router.push('/messages')
        });
    },

    findUser () {
      // TODO: fix it - not working smoothly when user is deleted from search field - p tag is helping, but is not good approach !!
      // console.log(value, this.form.receiver)
      // console.log(this.$event)
      /*if(this.form.receiver.endsWith(',')) {
        console.log('YES!!')
      }*/
      let value = this.form.receiver
      let payload = {}
      if(value !== null) {
        let keyword = null
        let endpoint = null
        let fields = []
        if (this.isLoggedUser.roles === 'participant') {
          endpoint = ApiProjects
          if (value.charAt(0) === '@') {
            keyword = value.substring(1)
          } else {
            keyword = value
          }
          fields = ['title', 'slug']
        } else {
          if (value.charAt(0) === '@') {
            // console.log('first char: ', value.charAt(0), ' without @:', keyword)
            // search for projects
            endpoint = ApiProjects
            keyword = value.substring(1)
            fields = ['title', 'slug']
          } else {
            // search for users
            endpoint = ApiUsers
            keyword = value
            fields = ['name', 'slug', 'email']
          }
        }

        let payload = {
          endpoint: endpoint,
          field: fields,
          value: keyword
        }

        if (keyword.length > 0) {
          this.liveSearch(payload)
        } else {
          payload.keyword = null
          this.name = null
        }
      }
    },

    /**
     * selected might be name of user or name (title) of
     * the project
     * message can be sent to one user or to all users
     * of the project
     *
     * @param  {object} value with id, and name or title
     *
     */
    onSelected(value) {
      // console.log('selected', value)
      if (value.hasOwnProperty('title')) {
        this.name = value.title
        this.receiver_ids.push({p: value.slug})
      } else {
        this.name = value.name
        this.receiver_ids.push({u: value.slug})
      }
      this.form.receiver_id = value.id
      this.form.receiver_ids = this.receiver_ids
      this.form.receiver = this.name + ','
    },

  },

  mounted () {
    console.log('create message', this.isLoggedUser.roles)
    // create new item - form should be empty
  }
}
</script>
<style scoped>
  .dropdown.is-horizontal {
    display: inherit;
  }
</style>
